<template>
	<div>
		<main class="container-wide">
			<div class="flex space-between mb-40">
				<div>
					<h1 class="mb-10">Sáttmálar</h1>

					<p class="color-grey">Yvirlit yvir avtalur við kundar hjá Terminal.fo</p>
				</div>

				<div class="flex align-center">
					<div class="mr-10 relative search-input small">
						<input
							type="text"
							class="focus:background-light-grey-100"
							v-model="searchString"
							@keydown.esc="searchString = ''"
							placeholder="Leita"
						/>

						<div class="icon-search"></div>
					</div>

					<router-link class="btn-add" :to="{ name: 'AgreementCreate' }" title="Stovna sáttmála"></router-link>
				</div>
			</div>

			<label class="cursor-pointer">
				<input type="checkbox" v-model="hideInactive" class="mr-5" />

				Fjal óvirknar
			</label>

			<table v-if="!loading">
				<thead>
					<th>&nbsp;</th>

					<th>Kundi</th>

					<th>Slag</th>

					<th>Frá</th>

					<th>Til</th>

					<th>Status</th>

					<th class="right">&nbsp;</th>
				</thead>

				<tbody>
					<tr class="relative" v-for="(agreement, index) in filteredAgreements" :key="`agreement-${index}`">
						<td>
							<i class="far fa-check-circle color-green mr-20" v-if="agreement.active" title="Sáttmálin er virkin"></i>

							<i class="far fa-times-circle color-red mr-20" v-if="!agreement.active" title="Sáttmálin er óvirkin"></i>
						</td>
						<td>
							<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
								<span :title="agreement.customer.name">
									{{ agreement.customer ? agreement.customer.name : '-' }}
								</span>

								<span v-if="hasNotifications(agreement)" class="ml-5 color-blue"
									><i class="fas fa-exclamation-circle" title="Sáttmálin hevur ólisin skjøl"></i
								></span>
							</router-link>
						</td>

						<td>{{ agreement.type ? agreement.type.name : '-' }}</td>

						<td>{{ agreement.from ? new Date(agreement.from).format('%d/%m/%y kl. %H:%M') : '-' }}</td>

						<td>{{ agreement.to ? new Date(agreement.to).format('%d/%m/%y kl. %H:%M') : '-' }}</td>

						<td>{{ countDoneSteps(agreement.agreementSteps) }} av {{ agreement.agreementSteps.length }}</td>

						<td class="right">
							<i class="far fa-edit cursor-pointer mr-20" @click="editAgreement(agreement.id)" title="Rætta sáttmála"></i>

							<i
								v-if="!agreement.agreementLink || !agreement.agreementLink.active"
								class="fas fa-unlink color-red"
								title="Leinkjan er óvirkin"
							></i>

							<router-link
								v-if="agreement.agreementLink && agreement.agreementLink.active"
								:to="{ name: 'Client', query: { token: agreement.agreementLink && agreement.agreementLink.token } }"
								target="_blank"
							>
								<i class="fas fa-link color-green" title="Einnýtisleinkja"></i>
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="flex justify-center mt-80" v-if="loading">
				<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
			</div>
		</main>

		<LinkOverlay :visible="linkOverlayVisible" :agreement="agreement" @close="linkOverlayVisible = false" />
	</div>
</template>

<script>
import LinkOverlay from '@/components/LinkOverlay.vue';

import axios from 'axios';

export default {
	name: 'Agreements',

	components: {
		LinkOverlay,
	},

	data() {
		return {
			loading: true,
			searchString: '',
			agreements: [],

			linkOverlayVisible: false,
			agreement: null,

			hideInactive: true,
		};
	},

	computed: {
		apiURL() {
			return process.env.VUE_APP_TERMINAL_API_URL;
		},

		filteredAgreements() {
			return this.agreements.filter((agreement) => {
				if (this.hideInactive && !agreement.active) {
					return false;
				}

				if (agreement.customer && agreement.customer.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1) {
					return true;
				}

				return false;
			});
		},
	},

	mounted() {
		setTimeout(() => {
			if (this.loading !== false) {
				this.loading = true;
			}
		}, 100);

		axios
			.get('/agreements')
			.then((response) => {
				this.agreements = response.data;
			})
			.catch((error) => {
				console.log('Error fetching agreements from E-conomic', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		createLink(agreement) {
			this.linkOverlayVisible = true;
			this.link = `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${agreement.agreementLink.token}`;
			this.agreement = agreement;
		},

		showAgreement(agreementId) {
			this.$router.push({ name: 'AgreementShow', params: { id: agreementId } });
		},

		editAgreement(agreementId) {
			this.$router.push({ name: 'AgreementEdit', params: { id: agreementId } });
		},

		closeLinkOverlay() {
			this.linkOverlayVisible = false;
			this.agreement = null;
		},

		countDoneSteps(agreementSteps) {
			return agreementSteps.reduce((accumulator, step) => {
				return accumulator + (step.done ? 1 : 0);
			}, 0);
		},

		hasNotifications(agreement) {
			return agreement.notify_terminal_of_changes;
		},
	},
};
</script>

<style lang="scss" scoped>
.notification {
	position: absolute;
	top: 50%;
	left: 0;

	transform: translate(-50%, -50%);
}
</style>
